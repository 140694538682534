import images from '../../images'

function Footer() {
    const redirect = () => {
        window.scrollTo(0, 0);
    }

    return (
        <footer className="padding">
            
            <div className="container-xl">
            <div className="grid-container footer-container">
            
            <div className="item1 redirect" >
                <i className="fa fa-long-arrow-up" onClick={redirect}></i>
            </div>

            <img src={images.main[0]} alt="Footer logo" className="item2 footer-logo" />
                
            <div className="item3">
                <span><i className="fa fa-phone"> &nbsp;</i>(919) 271-8370</span>
                <br />
                <span><i className="fa fa-envelope"> &nbsp;</i>hende0469@gmail.com</span>
                <br /> 
                <span><i className="fa fa-map-marker"> &nbsp;</i>Raleigh-Durham, NC</span>
            </div>
                
                <span className="item4">&copy; JNH Wash 2022</span>
            </div>
            </div>
        </footer>
    );
}

export default Footer;