import { Link } from 'react-router-dom'
import images from '../../images' /*Images object from images folder*/
import PageTitle from '../PageTitle';


function Home() {
    let concreteCard = images.home[1];
    let homeExtCard = images.home[2];
    let patioCard = images.home[3];
   
    

    return (
        <main>
            <PageTitle 
                title="Time for a deep clean!" 
                intro="While other pressure washing companies offer solely exterior cleaning. JNH Power Washing goes beyond that to meet our customers on every need/desire that they might have. Our company believes in: Convenience, Professionalism, Integrity, Tenacity, Knowledge and Relationships." 
               
            ></PageTitle>

            <h2 className="text-center">Services:</h2>

            <div id="about" className="grid-container cards-container padding">
                <div className="card item1">
                 
                    <img className="card-img-top" src={homeExtCard} alt="About card"/>
                    
                    
                    <div className="card-body text-center">
                        <h3 className="card-title p-1">Home Exterior</h3>
                        <p className="card-text">Exterior walls, gutters, etc.</p>
                    </div>
                    
                     {/* 
                     <div className="card-footer">
                        <Link to="../about">
                            <button type="button" className="btn btn-outline-light btn-md w-100">
                                Learn more &nbsp;<i className="fa fa-long-arrow-right"></i>
                            </button>
                        </Link>
                    </div>
                    */}
                </div>

                
                <div className="card item2">
                
                <img className="card-img-top" src={concreteCard} alt="Lessons card" />
                
                
                <div className="card-body text-center">
                    <h3 className="card-title p-1">Concrete Areas</h3>
                    <p className="card-text">Driveways, patios and sidewalks</p>
                </div>
                {/* <div className="card-footer">
                    <Link to="../lessons">
                        <button type="button" className="btn btn-outline-light btn-md w-100">
                            Learn more &nbsp;<i className="fa fa-long-arrow-right"></i>
                        </button>
                    </Link>
                </div>
                */}
                </div>

                <div className="card item3">
                
                <img className="card-img-top " src={patioCard} alt="Boarding card" />
                
                
                <div className="card-body text-center">
                    <h3 className="card-title p-1">Patio Items</h3>
                    <p className="card-text">Miscellaneous outdoor items
                    </p>
                </div>
                
                {/* 
                <div className="card-footer">
                    <Link to="../boarding">
                        <button type="button" className="btn btn-outline-light btn-md w-100">
                            Learn more &nbsp;<i className="fa fa-long-arrow-right"></i>
                        </button>
                    </Link>
                </div> 
                */}
                </div>

               

               
    
            </div>

            

            <PageTitle 
                title="What makes JNH Wash special?" 
                intro="JNH Power Washing specializes in soft washing techniques which involves using chemicals to wash away dirt, residue and mold without using excessive amounts of water like traditional power washing. We take special care to protect plants and the surrounding areas using a combination of coverings and watering plants before and after washing has occurred. This technique not only uses much less water but it also greatly alleviates the chance of mold and dirt reoccurences, thus requiring less treatments over time." 
               
            ></PageTitle>

           

         

           
            
        </main>

    );
}

export default Home;