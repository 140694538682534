import PageTitle from '../PageTitle';

function Contact() {
    return(
        <main>
            <PageTitle 
                title="Let's get in touch!"
                intro="If you have any questions or would you like to set up a service appointment please send us your message below. We will get back to you as quickly as possible!"
            ></PageTitle>

            <div id="about" className="grid-container contact-container padding">
                <div className="item1" id="form_container">
                    <form name="contact" method="post">
                    <input type="hidden" name="form-name" value="contact" />
                    <input type="hidden" name="subject" 
  value="Inquiry for JNH Wash services" />
                        <div className="form-group">
                            <label for="message">
                                Message:</label>
                            <textarea className="form-control" type="textarea" id="message" name="message"
                                maxlength="6000" rows="6"></textarea>
                        </div>
                        <div className="form-row">
                            <div className="form-group col-sm-6">
                                <label for="name">Your Name:</label>
                                <input type="text" className="form-control" id="name" name="name" required />
                            </div>
                            <div className="form-group col-sm-6">
                                <label for="email">Email:</label>
                                <input type="email" className="form-control" id="email" name="email" required />
                            </div>
                        </div>
                        
                            <div className="form-group text-center">
                                <button type="submit" className="btn btn-outline-light btn-md w-50">Submit&nbsp;<span><i className="fa fa-envelope"></i></span></button>
                            </div>
                    </form>


                    <div id="success_message" style={{width:`100%`, height:`100%`, display:`none`}}>
                        <h3>Your message was sent successfully!</h3>
                    </div>
                    <div id="error_message" style={{width:`100%`, height:`100%`, display:`none`}}>
                        <h3>Error</h3>
                        <p>Sorry there was an error sending your message.</p>
                    </div>

                </div>
            </div>
                
                

             
            </main>
        
    );
}

export default Contact;