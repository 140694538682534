import logo from './images/jnhwash.png'
import hero from './images/hero.jpg'
import brick1 from './images/brick1.jpg'
import brick2 from './images/brick2.jpg'
import chair1 from './images/chair1.jpg'
import chair2 from './images/chair2.jpg'
import driveway1 from './images/driveway1.jpg'
import driveway2 from './images/driveway2.jpg'
import gutter from './images/gutter.jpg'
import redChair from './images/redChair.jpg'
import sideBef from './images/sideBef.jpg'
import sideAft from './images/sideAft.jpg'




const images = {
    main : [logo, hero],
    home: [driveway1, driveway2, redChair, gutter ],
    gallery: [chair1, chair2, brick2, brick1, sideBef, sideAft]
     }

export default images;


/*Creates array of images from images folder named "images" for import into the Gallery component*/