import images from '../../images' /*Array of images from images folder*/
import PageTitle from '../PageTitle';

 
let galleryImages = images.gallery.map((item, index) => {
    return <img src={images.gallery[index]} alt={item}  />
       
    })

function Gallery() {
    return (
        <main>
            <PageTitle title="Photo Gallery"></PageTitle>
            
            <h3 class="text-center"> Check out some of our work!</h3>

            <div className="grid-container gallery-container padding">
                {galleryImages}     
                
            </div> 
             
        </main>
    );
}

export default Gallery;